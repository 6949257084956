<template>
  <div>
    <b-card>
      <div class="d-flex align-items-center">
        <img
          :src="logoProgram"
          width="60rem"
          class="mr-2"
        >
        <b-row>
          <b-col cols="12">
            <h4 class="m-0 text-gray-800">
              {{ nameProgram }}
            </h4>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-row>
      <b-col
        cols="12"
        sm="4"
        class="d-flex"
      >
        <b-card class="w-100">
          <h5 class="mt-0 mb-1 text-gray-800">
            Você está liberando:
          </h5>
          <div class="mb-2">
            <li
              v-for="(nameMedicaion, index) in nameMedications"
              v-show="nameMedications.length > 1 && index > 0 ? showMore : true"
              :key="nameMedicaion.item.name"
              class="mb-0 text-gray-800"
            >
              <strong>{{ nameMedicaion.item.name }}</strong>
              <span v-if="nameMedicaion.item.type === 'KIT'">
                <ul
                  v-for="item in nameMedicaion.item.item_childs_info"
                  :key="item.id"
                >
                  <li>{{ item.name }}</li>
                </ul>
              </span>
            </li>
          </div>
          <b-collapse
            id="collapse-1"
            v-model="showMore"
            class=""
          >
            <div>
              <b-row>
                <b-col>
                  <span class="text-primary font-weight-bold">É simples e prático</span>
                </b-col>
              </b-row>
              <b-row
                :class="{'mt-1 p-1': !sm, 'mt-1': sm}"
                align-h="between"
              >
                <b-col
                  sm="5"
                  cols="12"
                  :class="{'mb-1': sm}"
                >
                  <b-row>
                    <b-col
                      sm="12"
                      cols="2"
                    >
                      <div class="text-center">
                        <b-img
                          class="mb-1"
                          :src="require('@/assets/images/icons/pen.svg')"
                        />
                      </div>
                    </b-col>
                    <div :class="{'text-left col-9 ml-2': sm, 'text-center': !sm}">
                      <p class="text-gray-800 mb-0">
                        Preencha os campos com os dados do paciente.
                      </p>
                    </div>
                  </b-row>
                </b-col>
                <b-col
                  sm="5"
                  cols="12"
                >
                  <b-row>
                    <b-col
                      sm="12"
                      cols="2"
                    >
                      <div class="text-center">
                        <b-img
                          class="mb-1"
                          :src="require('@/assets/images/icons/send.svg')"
                        />
                      </div>
                    </b-col>
                    <div :class="{'text-left col-9 ml-2': sm, 'text-center': !sm}">
                      <p class="text-gray-800 mb-0">
                        Um convite contendo o link para cadastro será enviado
                      </p>
                    </div>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-collapse>
          <div class="d-sm-none mt-1 text-center">
            <b-button
              variant="link"
              @click="showMore = !showMore"
            >
              <span class="text-primary">{{ showMore ? 'Menos detalhes e instruções' : 'Mais detalhes e instruções' }}</span>
              <feather-icon
                class="ml-1"
                :icon="showMore ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                stroke="#0058F9"
                size="15"
              />
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col class="d-flex">
        <b-card
          no-body
          class="pb-2 pl-2 pr-2 w-100"
        >
          <h4 class="mt-2 text-gray-800">
            Dados do paciente
          </h4>
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row class="mt-2">
                <b-col
                  cols="12"
                >
                  <label
                    :style="activeWhatsapp ? 'font-size: 1rem; font-weight: 500; color: #171725;' : '' "
                    for="nome"
                  >
                    Nome Completo*
                  </label>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|full_name"
                      name="nome"
                    >
                      <b-form-input
                        v-model="name"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                >
                  <div
                    v-if="activeWhatsapp"
                    class="d-flex"
                  >
                    <div class="mr-auto">
                      <b-row>
                        <label
                          style="font-size: 1rem; font-weight: 500; color: #171725;"
                          for="radio-inline"
                          class="mr-1 ml-1"
                        >
                          Enviar link via:
                        </label>
                        <b-form-radio-group
                          v-model="provider"
                          name="radio-inline"
                          class="mb-1 ml-1 mb-sm-0 ml-sm-0"
                        >
                          <b-form-radio
                            v-if="activeWhatsapp"
                            :value="{ text: 'WhatsApp', value: 'WHATSAPP', placeholder: '00 00000-0000' }"
                          >
                            WhatsApp
                          </b-form-radio>
                          <b-form-radio :value="{ text: 'SMS', value: 'SMS', placeholder: '00 00000-0000' }">
                            SMS
                          </b-form-radio>
                          <b-form-radio :value="{ text: 'e-mail', value: 'EMAIL', placeholder: 'seuemail@email.com' }">
                            E-mail
                          </b-form-radio>
                        </b-form-radio-group>
                      </b-row>
                    </div>
                    <div>
                      <b-button
                        id="popover-guideline"
                        variant="outline-light"
                        class="btn-no-hover rounded-circle p-0 ml-1"
                      >
                        <feather-icon
                          icon="InfoIcon"
                          stroke="blue"
                          size="16"
                        />
                      </b-button>
                      <b-popover
                        target="popover-guideline"
                        variant="primary"
                        triggers="click blur"
                        custom-class="bariatric-popover"
                      >
                        <template #title>
                          <h6 class="text-white m-0">
                            Orientações
                          </h6>
                        </template>
                        <p>Escolha o canal por onde seu paciente vai receber o link para ativar o benefício do programa Primeira Caixa. Oriente que durante a ativação é preciso inserir o mesmo número de celular ou e-mail que foi utilizado nesta liberação.</p>
                      </b-popover>
                    </div>
                  </div>
                  <div v-else>
                    <label>Celular ou e-mail*</label>
                  </div>
                  <b-form-group>
                    <validation-provider
                      ref="identificationInput"
                      #default="{ errors }"
                      rules="required|positive"
                      :name="provider ? provider.text : `celular ou e-mail`"
                    >
                      <b-form-input
                        v-model="identification"
                        v-mask="isNumberIdentification ? '## #####-####': ''"
                        :placeholder="provider ? provider.placeholder : ''"
                        :disabled="activeWhatsapp ? (provider ? false : true) : false"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <small class="text-danger">
                      {{ identificationErrorMessage }}
                      <ul v-if="identificationErrorItems.length > 0">
                        <li
                          v-for="item in identificationErrorItems"
                          :key="item"
                        >
                          {{ item }}
                        </li>

                      </ul>
                    </small>

                  </b-form-group>
                </b-col>
              </b-row>
              <div class="mt-2 d-flex justify-content-center justify-content-sm-end">
                <b-button
                  variant="outline-primary"
                  @click.prevent="cancel()"
                >
                  Cancelar
                </b-button>
                <b-button
                  class="ml-2"
                  variant="primary"
                  type="submit"
                  :disabled="loading"
                  @click.prevent="validationForm"
                >
                  <b-spinner
                    v-show="loading"
                    small
                  />
                  Enviar
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BForm, BFormInput, BButton, BSpinner, BFormRadioGroup, BFormRadio, BPopover, BImg, BCollapse,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { breakpointsBootstrapV5, useBreakpoints } from '@vueuse/core'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BSpinner,
    BFormRadioGroup,
    BFormRadio,
    BPopover,
    BImg,
    BCollapse,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const provider = ref(null)

    const showMore = ref(!(store.getters['app/currentBreakPoint'] === 'xs' || store.getters['app/currentBreakPoint'] === 'sm'))
    const breakpoints = useBreakpoints(breakpointsBootstrapV5)
    const sm = breakpoints.smallerOrEqual('sm')
    watch(() => store.getters['app/currentBreakPoint'], () => {
      showMore.value = !(store.getters['app/currentBreakPoint'] === 'xs' || store.getters['app/currentBreakPoint'] === 'sm')
    })

    return {
      provider,
      showMore,
      sm,
    }
  },
  data() {
    return {
      nameMedication: '',
      name: '',
      identification: '',
      isNumberIdentification: true,
      options: {
        cell: {
          blocks: [2, 5, 4],
          delimiters: [' ', '-'],
        },
        email: {
          blocks: [0],
        },
      },
      loading: false,
      identificationErrorMessage: '',
      identificationErrorItems: [],
    }
  },

  computed: {
    logoProgram() {
      return this.$store.getters['stock/logoProgram']
    },
    nameProgram() {
      return this.$store.getters['stock/nameProgram']
    },
    activeWhatsapp() {
      return this.$store.state.stock.program?.active_whatsapp
    },
    providerPrimitiveType() {
      return this.isNumberIdentification ? 'SMS' : 'EMAIL'
    },
    providerPrimitiveText() {
      return this.isNumberIdentification ? 'SMS' : 'e-mail'
    },
    nameMedications() {
      const medication = this.$store.getters['stock/medicationSelected']
      return medication.map(value => ({ item: value.item_info })).sort((a, b) => a.item.name.localeCompare(b.item.name))
    },
  },

  watch: {
    identification() {
      this.identificationErrorMessage = ''
      this.identificationErrorItems = []

      if (this.provider) {
        return
      }

      if (/^[A-Za-z]/g.test(this.identification)) {
        this.isNumberIdentification = false
      } else {
        this.isNumberIdentification = true
      }
    },
    provider() {
      this.identificationErrorMessage = ''
      this.identificationErrorItems = []
      this.isNumberIdentification = this.provider.value === 'SMS' || this.provider.value === 'WHATSAPP'
      if (this.isNumberIdentification) {
        extend('positive', async value => {
          const newValue = value.replace(/\D/g, '')
          if (newValue.length === 11) {
            return true
          }
          return 'Insira um celular com o DDD + número.'
        })
      } else {
        extend('positive', () => {
          if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.identification)) {
            return true
          }

          return 'Insira um e-mail válido.'
        })
      }
      this.$refs.identificationInput.reset()
    },
  },

  mounted() {
    localize(this.$store.getters['cuido/locale'])

    extend('positive', async value => {
      if (this.isNumberIdentification) {
        const newValue = value.replace(/\D/g, '')
        if (newValue.length === 11) {
          return true
        }
        return 'Insira um celular com o DDD + número.'
      }

      if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.identification)) {
        return true
      }

      return 'Insira um e-mail válido.'
    })
    extend('full_name', async value => {
      if (value?.split(' ').length > 1 && value?.split(' ')?.[1] !== '') {
        return true
      }

      return 'Digite o nome completo.'
    })
  },
  destroyed() {
    this.$store.commit('stock/RESET_MEDICATION_SELECTED')
  },
  created() {
    this.verifyExistsMedicationSelected()
  },

  methods: {
    verifyExistsMedicationSelected() {
      const medication = this.$store.getters['stock/medicationSelected']
      if (medication.length === 0) {
        this.$router.push({ name: 'stock' })
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true

          const medication = this.$store.getters['stock/medicationSelected']
          const idMedication = medication.map(value => value.id)
          const eans = medication.map(value => value.item_info.ean)

          const codeInfo = {
            code_info: {
              name: this.name,
              provider_type: this.provider ? this.provider.value : this.providerPrimitiveType,
              identification: this.isNumberIdentification ? `55${this.identification.replace(/\D/g, '')}` : this.identification,
              program_id: this.$store.getters['stock/idProgram'],
            },
          }
          const stockIds = {
            stock_ids: idMedication,
          }

          const eansMedications = {
            eans_medications: eans,
          }

          this.$store.dispatch('stock/save', { codeInfo, stockIds, eansMedications })
            .then(() => {
              this.alertSuccess()
            })
            .catch(err => {
              this.loading = false
              this.setErrorMessage(err.response.data.error)

              if (this.identificationErrorMessage === '') {
                this.$swal({
                  title: 'Atenção!',
                  text: 'Verificamos que o celular ou o e-mail informado já foi utilizado para este item.',
                  icon: 'error',
                  iconHtml: '!',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                  confirmButtonText: '< Voltar',
                  buttonsStyling: false,
                  didOpen: this.loading = false,
                }).then(result => {
                  if (result.isConfirmed) {
                    this.$router.push('stock-medication')
                  }
                })
              }
            })
        }
      })
    },
    cancel() {
      this.$router.push('stock-medication')
    },
    isUpperCase(str) {
      return str === str.toUpperCase()
    },
    alertSuccess() {
      this.$swal({
        title: 'Convite enviado!',
        text: `Agora, o paciente deve realizar o cadastro em primeiracaixa.com.br em até 72 horas. As instruções serão enviadas via ${this.provider ? this.provider.text : this.providerPrimitiveText} em breve.`,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        didOpen: this.loading = false,
      }).then(() => {
        this.$store.commit('stock/RESET_MEDICATION_SELECTED')
        this.$router.push('/stock')
      })
    },
    saveStock(codeInfo, stockIds, eansMedications) {
      this.$store.dispatch('stock/save', { codeInfo, stockIds, eansMedications })
        .then(() => {
          this.alertSuccess()
        })
        .catch(() => {
          this.$swal({
            title: 'Atenção!',
            text: 'Verificamos que o celular ou o e-mail informado já foi utilizado para este item.',
            icon: 'error',
            iconHtml: '!',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            confirmButtonText: '< Voltar',
            buttonsStyling: false,
            didOpen: this.loading = false,
          }).then(result => {
            if (result.isConfirmed) {
              this.$router.push('stock-medication')
            }
          })
        })
    },
    setErrorMessage(error) {
      if (error.code === 500005) {
        const firstInvalidInput = error.invalid_inputs[0]

        if (firstInvalidInput.input === 'code_info.identification') {
          const errorRules = JSON.parse(firstInvalidInput.rules[0])
          if (errorRules.message === 'limit_execeeded') {
            this.identificationErrorMessage = `O número de itens ultrapassa o limite, para esse cadastro você pode liberar até ${errorRules.current_limit} item(ns)`
            if (errorRules.current_limit === 0) {
              this.identificationErrorMessage = 'O número de itens ultrapassa o limite.'
            }
          }
          return
        }

        if (firstInvalidInput.input.includes('stock_ids')) {
          error.invalid_inputs.forEach(invalidInput => {
            if (invalidInput.rules.includes('medication_already_sent_for_this_identification')) {
              const splitString = invalidInput.input.split('stock_ids.')
              if (splitString.length > 1) {
                this.identificationErrorItems.push(this.$store.getters['stock/medicationSelected'][splitString[1]].item_info.name)
              }
            }
          })
          if (this.identificationErrorItems.length > 0) {
            this.identificationErrorMessage = 'Os itens a seguir já foram liberados para esse celular ou e-mail:'
          }
        }
      }
    },
  },
}
</script>
